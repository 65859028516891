<template>
  <v-card :loading="loadingClient" fill-height flat class="wl-page-module">
    <v-card-title>Positions</v-card-title>
    <v-card-text v-if="loadingClient">
      <v-skeleton-loader type="table-tbody" />
    </v-card-text>
    <v-card-text v-else>
      <v-text-field
        label="Search Positions"
        v-model="searchPositionsValue"
        outlined
        color="bobGreen"
        @keydown="initSearch"
      />
      <v-data-table
        :headers="headers"
        :items="positions"
        :search="searchPositions"
        :page="page"
      >
        <template v-slot:item.cashBalance="{ item }">
          <v-chip-group v-if="item.cashBalance">
            <v-chip outlined>
              {{
                `Target ${formatPercent(op(item, 'cashBalance/targetWeight'))}%`
              }}
            </v-chip>

            <v-chip color="primary">
              {{
                `Actual ${formatPercent(
                  op(item, 'cashBalance/currentWeight')
                )}%`
              }}
            </v-chip>
            <v-chip outlined color="success">
              {{
                `Budget Available: ${currencyFilter(
                  op(item, 'cashBalance/symbolBudget')
                )}`
              }}
            </v-chip>
          </v-chip-group>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatPercent } from '@/utils/number'
import currencyFilter from '@/utils/currency'
import { ClientPositionColumns } from './lib/columns'
import op from 'simple-object-path'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      client: 'client/getClient',
      loadingClient: 'client/getLoadingClient',
    }),
  },
  watch: {
    client() {
      this.setPositions()
    },
  },
  data() {
    return {
      op,
      formatPercent,
      currencyFilter,
      positions: [],
      headers: ClientPositionColumns,
      searchPositionsValue: undefined,
      searchPositions: undefined,
      page: 1,
    }
  },
  methods: {
    setPositions() {
      this.positions = this.client?.positions || []
    },
    initSearch($event) {
      this.debounce = setTimeout(() => {
        this.searchPositions = this.searchPositionsValue
        this.page = 1
      }, 1000)
      if ($event.key === 'Enter') {
        this.searchPositions = this.searchPositionsValue
        this.page = 1
        clearTimeout(this.debounce)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
